import React from "react"
/* eslint react/prop-types: 0 */
import styles from "./style.css"

const Nav = props => {
  const dots = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i
    dots.push(
      <span
        key={`step-${i}`}
        className={`wizard-dot ${isActive ? "active" : ""}`}
        onClick={() => props.goToStep(i)}
      >
        &bull;
        <br />
      </span>
    )
  }

  return <div className="wizard-nav">{dots}</div>
}

export default Nav
