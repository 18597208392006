import React, { Component } from "react"
import DeckglMap from "../components/Globals/DeckGLMap/DeckGLMap.js"
import StepWizard from "react-step-wizard"
import Nav from "../components/Globals/MultiStepForm/Nav.js"
import SEO from "../components/seo"

import { IoIosArrowForward } from "react-icons/io"

export default class Contact extends Component {
  state = {
    name: "",
    stepWizard: null,
    activeStep: 1,
    totalSteps: 3,
  }

  /*  onSubmit = () => {
    console.log("submit form")
  }*/

  onNextClick = () => {
    const { stepWizard, totalSteps, activeStep } = this.state
    if (stepWizard) {
      return e => {
        e.preventDefault()
        stepWizard.goToStep(activeStep + 1)
      }
    }
    return e => {
      e.preventDefault()
    }
  }
  onStepChange = ({ activeStep, previousStep }) => {
    console.log("onStepChange:", activeStep)
    this.setState({ activeStep })
  }

  render = () => {
    const custom_transitions = {
      enterRight: "fade-in",
    }
    const { totalSteps, activeStep } = this.state
    return (
      <>
        <SEO title="Contact me" />
        <div className="contact_background">
          <div className="contact_form_container">
            <div className="contact_form_container_2">
              <div className="contact_title_container">
                <h1>Contact me</h1>
              </div>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="success"
              >
                <div className="NextButton">
                  <button
                    className="btn"
                    onClick={this.onNextClick()}
                    style={{
                      display: activeStep < totalSteps ? "block" : "none",
                    }}
                  >
                    <IoIosArrowForward size={60} />
                    <p>or press ENTER</p>
                  </button>

                  <button
                    className="btn"
                    type="submit"
                    value="Send Message"
                    // className="special"
                    style={{
                      display: activeStep < totalSteps ? "none" : "block",
                    }}
                  >
                    <div>
                    <IoIosArrowForward size={60} />
                    <p>or press ENTER</p>
                    </div>
                  </button>
                </div>

                <input type="hidden" name="form-name" value="contact" />

                <StepWizard
                  nav={<Nav />}
                  instance={stepWizard => this.setState({ stepWizard })}
                  onStepChange={this.onStepChange}
                  transitions={custom_transitions}
                >
                  <div className="Step">
                    <h2> What's your name? </h2>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="John O'Conor"
                    />
                  </div>
                  <div className="Step">
                    <h2> What's your email address? </h2>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="John@example.com"
                    />
                  </div>

                  <div className="Step">
                    <h2> Write a message </h2>
                    <textarea
                      type="text"
                      name="message"
                      id="message"
                      rows="6"
                      placeholder="Your message here"
                    />
                  </div>
                </StepWizard>
              </form>
            </div>
          </div>

          <div className="map_container">
            <DeckglMap />
          </div>
        </div>
      </>
    )
  }
}
